import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-sprout-icon',
  imports: [],
  styleUrl: './icons.component.scss',
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [attr.width]="width()"
    [attr.height]="height()"
    viewBox="0 0 62 64"
    [attr.fill]="fill()"
  >
    <path
      d="M18.665 53.3337H45.3317"
      [attr.stroke]="stroke()"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.665 53.3329C41.3317 46.6663 28.7984 36.2663 34.665 26.6663"
      [attr.stroke]="stroke()"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.3317 25.0673C28.265 27.2007 30.1317 30.934 31.465 34.934C26.1317 36.0007 22.1317 36.0007 18.665 34.134C15.465 32.534 12.5317 29.0673 10.665 22.934C18.1317 21.6007 22.3984 22.934 25.3317 25.0673Z"
      [attr.stroke]="stroke()"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.5994 15.9996C35.5663 19.1771 34.5436 22.896 34.6661 26.6663C39.7328 26.3996 43.4661 25.0663 46.1328 22.9329C48.7994 20.2663 50.3994 16.7996 50.6661 10.6663C43.4661 10.9329 39.9994 13.3329 37.5994 15.9996Z"
      [attr.stroke]="stroke()"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SproutIconComponent {
  stroke = input<string>('#6F9305');
  fill = input<string>('none');
  width = input<string>('24px');
  height = input<string>('24px');
}
