import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-button-favourite',
  imports: [],
  templateUrl: './button-favourite.component.html',
  styleUrl: './button-favourite.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonFavouriteComponent {
  isFavourite = input<boolean>(true);
  disabled = input<boolean>(false);
}
