import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

import { TAnnouncementListData } from '@components/pages/home/core/announcementsListTypes';

@Pipe({
  name: 'announcementName',
  standalone: true,
})
export class AnnouncementNamePipe implements PipeTransform {
  constructor(private titleCase: TitleCasePipe) {}

  transform(announcement: TAnnouncementListData): string {
    const { category, cereal, classification } = announcement;

    return `${this.titleCase.transform(category.label)} ${cereal ? cereal.name.toLowerCase() : ''} ${classification ? classification.name : ''}`;
  }
}
