<div class="announcement-card" [class.horizontal]="isHorizontal()">
  <div>
    @if (announcement().is_organization) {
      <a
        [routerLink]="[
          '/',
          ROUTE_ANNOUNCEMENT,
          announcement().category.code,
          announcement().slug,
        ]"
        [queryParams]="{ source: source() }"
      >
        <div class="org-banner" [class.smallImage]="smallImage()">
          <app-elevator-icon class="elevator"></app-elevator-icon>
          <span class="elevator-text">Предложение переработчика</span>
        </div>
      </a>
    }
    <div
      class="image-wrapper"
      [class.smallImage]="smallImage()"
      [class.organization]="announcement().is_organization"
    >
      <a
        [routerLink]="[
          '/',
          ROUTE_ANNOUNCEMENT,
          announcement().category.code,
          announcement().slug,
        ]"
        [queryParams]="{ source: source() }"
      >
        <img
          [ngSrc]="
            announcement().main_image?.url ??
            DEFAULT_MAIN_ANNOUNCEMENT_IMAGE_URL
          "
          fill
          alt="Фотография {{ announcement().name }}"
          class="main-photo"
          loading="lazy"
        />
      </a>
      <app-class-label
        [classification]="announcement().classification"
        class="class"
      ></app-class-label>
      <app-category-label
        [isGrainWaste]="announcement().category.code === 'grain_waste'"
        [label]="
          announcement().cereal !== null
            ? announcement().cereal?.name
            : announcement().category.label
        "
        class="category"
      ></app-category-label>
      @if (!isMyAnnouncement() && showFavourite()) {
        <app-button-favourite
          (click)="
            addingToFavouriteFunction({
              slug: announcement().slug,
              is_favorite: announcement().is_favorite,
            })
          "
          [isFavourite]="announcement().is_favorite"
          [disabled]="isFavouritesLoading()"
          class="favorite"
        ></app-button-favourite>
      }
    </div>
  </div>
  <a
    class="description-block"
    [class.smallImage]="smallImage()"
    [routerLink]="[
      '/',
      ROUTE_ANNOUNCEMENT,
      announcement().category.code,
      announcement().slug,
    ]"
    [queryParams]="{ source: source() }"
  >
    <div class="label date">
      {{ announcement().created_at | date: "dd.MM.yyyy HH:mm" }}
    </div>
    <div class="price">
      @if (announcement().is_contract_price) {
        Цена договорная
      } @else {
        {{ announcement().price | number: "1.0-2" : "ru-Ru" }} руб. / т

        <sup>{{ announcement().is_with_vat ? "С НДС" : "Без НДС" }}</sup>
      }
    </div>

    @if (announcement().name) {
      <div class="title">{{ announcement().name }}</div>
    } @else {
      <div class="title">{{ announcement() | announcementName }}</div>
    }

    @if (announcement().weight) {
      <div class="label">
        Объём: {{ announcement().weight | number: "1.0-2" : "ru-Ru" }} т
      </div>
    }
    <div class="label">
      {{ announcement().type.value === "sell" ? "Продажа" : "Покупка" }}
    </div>
    <div class="label">
      <!-- todo new address Не выделям жирным шрифтом? -->
      {{ announcement().address }}
    </div>
  </a>
</div>
