import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CategoryLabelComponent } from './category-label/category-label.component';
import { ClassLabelComponent } from './class-label/class-label.component';
import {
  TAnnouncementListData,
  TChangeFavourites,
} from '../core/announcementsListTypes';
import { AnnouncementNamePipe } from '@pipes/announcement-name.pipe';
import { ROUTE_ANNOUNCEMENT } from '@settings/routes';
import { ButtonFavouriteComponent } from '@components/ui/button-favourite/button-favourite.component';
import { TBreadcrumbsSource } from '@components/common/breadcrumbs/breadcrumb';
import { DEFAULT_MAIN_ANNOUNCEMENT_IMAGE_URL } from '@services/announcement.service';
import { ElevatorIconComponent } from '@components/ui/icons/elevator-icon.component';

@Component({
  selector: 'app-announcement-card',
  imports: [
    ClassLabelComponent,
    CategoryLabelComponent,
    ButtonFavouriteComponent,
    CommonModule,
    AnnouncementNamePipe,
    RouterModule,
    ElevatorIconComponent,
    NgOptimizedImage,
  ],
  templateUrl: './announcement-card.component.html',
  styleUrl: './announcement-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementCardComponent {
  protected readonly ROUTE_ANNOUNCEMENT = ROUTE_ANNOUNCEMENT;

  announcement = input.required<TAnnouncementListData>();
  isMyAnnouncement = input<boolean>(false);
  isFavouritesLoading = input<boolean>(false);
  isHorizontal = input<boolean>(false);
  smallImage = input<boolean>(false);
  showFavourite = input<boolean>(true);

  source = input<TBreadcrumbsSource>();

  addingToFavourite = output<TChangeFavourites>();
  addingToFavouriteFunction(data: TChangeFavourites) {
    if (!this.isFavouritesLoading()) {
      this.addingToFavourite.emit(data);
    }
  }

  protected readonly DEFAULT_MAIN_ANNOUNCEMENT_IMAGE_URL =
    DEFAULT_MAIN_ANNOUNCEMENT_IMAGE_URL;
}
